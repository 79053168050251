.MapsApp {
    .MapDraggableContainer {
        position: absolute;

        .MapImage {
            width: 450rem;
            height: 478.5rem;
        }
    }

    .CarIcon {
        position: absolute;
        top: 80%;
        left: 51%;
        width: 10rem;
    }

    .TopBar {
        position: absolute;
        width: calc(100% + 4rem);
        height: 16rem;
        top: -2rem;
        left: -2rem;
        background: var(--c-blue-dark);
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 4rem 4rem 2rem 4rem;
        box-shadow: 0 1rem 2rem hsla(0, 0, 0, 0.33);

        .StatusContainer {
            display: flex;
            flex-flow: nowrap;
            align-items: center;

            > img {
                width: 6rem;
                height: 6rem;
                border-radius: 3rem;
                border: 0.33rem solid #d8d8d8;
                margin-right: 2rem;
            }

            h2 {
                color: white;
                font-size: 2rem;
                font-weight: 700;
                line-height: 1rem;

                img {
                    height: 2rem;
                    margin-left: 2rem;
                }
            }

            p {
                line-height: 1rem;
                font-size: 1.5rem;
                font-weight: 500;
                color: white;
            }
        }

        .EnvironmentContainer {
            display: flex;
            flex-flow: row nowrap;

            .WeatherIcon {
                height: 3rem;
                position: relative;
                top: -0.5rem;
                margin-right: 2rem;
            }

            span {
                font-size: 2rem;
                font-weight: 700;
                color: white;
                margin-right: 2rem;
                white-space: nowrap;

                &:last-child {
                    margin-right: 0 !important;
                }
            }
        }
    }

    .BottomBar {
        position: absolute;
        width: calc(100% + 4rem);
        height: 14rem;
        bottom: -2rem; // Overflow so maps color on edge doesn't bleed when blurring
        left: -2rem;
        background: var(--c-blue-dark);
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 4rem 6rem 6rem 6rem;
        box-shadow: 0 1rem 2rem hsla(0, 0, 0, 0.33);

        .Info {
            width: 15%;
            color: white;
            text-align: center;

            &.TimeLeft {
                width: 22%;
            }

            &.DistanceLeft {
                width: 22%;
                margin-right: 12%;
            }

            &.ArrivalTime {
                width: 44%;
            }

            .Quantity {
                display: block;
                font-size: 2rem;
                line-height: 4rem;
                font-weight: 800;
            }

            .Unit {
                font-size: 1.5rem;
                font-weight: 500;
            }
        }

        .ScenarioStartTrigger {
            position: absolute;
            right: 0;
            height: 100%;
            width: 20rem;
            opacity: 0;
        }
    }

    .CalendarIcon {
        position: relative;
        bottom: -0.66rem;
        right: -0.33rem;
        width: 2.5rem;
        height: auto;
    }

    .MeetingParticipants {
        height: 3.5rem;
    }
}
