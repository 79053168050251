$cellHeight: 10rem;
$fullHeight: 30rem;

.pickerRoot {
    display: flex;
    flex-direction: column;
    color: white;

    .topContainer {
        height: 3rem;
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        padding-left: 36rem;

        .timeTitle {
            font-size: 3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 12.2rem;
        }
    }

    .pickerContainer {
        display: flex;
        height: $fullHeight;
        overflow: hidden;
        font-size: 3.5rem;
        font-weight: 700;
        letter-spacing: 1.17px;
        position: relative;

        .pickerColumn {
            overflow: scroll;
            scroll-snap-type: both mandatory;
            scrollbar-width: none; /* Firefox */
            -ms-overflow-style: none; /* IE 10+ */
            &::-webkit-scrollbar {
                width: 0;
                background: transparent; /* Chrome/Safari/Webkit */
            }

            .pickerItem {
                display: flex;
                align-items: center;
                height: $cellHeight;
                scroll-snap-align: start;
                padding: 0 12rem 0 6rem;
            }

            .centered {
                justify-content: center;
                padding: 0 2rem;
            }
        }

        .spacer {
            min-width: 1px;
        }

        .colonContainer {
            height: $fullHeight;
            padding: 0 1rem;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .topHorizontalLine {
            position: absolute;
            background-color: #00bdbb;
            height: 1px;
            top: $cellHeight;
            width: 100%;
            left: 0;
        }

        .bottomHorizontalLine {
            position: absolute;
            background-color: #00bdbb;
            height: 1px;
            top: 20rem;
            width: 100%;
            left: 0;
        }
    }
}
