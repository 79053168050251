.ToastContainer {
    position: absolute;
    // Height is 30 rem but content is 25 rem to ensure background for overshoot during animation
    height: 40rem;
    padding-top: 10rem;
    background: hsla(212, 13%, 19%, 1);
    width: 101%; // Slightly wider and overflowing horizontally so that blur doesn't reveal map
    right: -0.25%;
    box-shadow: 0 1rem 2rem hsla(0, 0, 0, 0.33);

    .ContentContainer {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        height: 28rem;
        padding: 2rem 4rem;

        .IconContainer {
            width: 8rem;
            margin-right: 2rem;
            margin-top: -4rem;

            img {
                width: 100%;
            }
        }

        .MetadataContainer {
            margin-right: 2rem;
            flex-grow: 1;

            h2 {
                color: white;
                font-size: 2.5rem;
                font-weight: 700;
                letter-spacing: 0.05rem;
                line-height: 3.5rem;
                margin: -1.5rem 0 -2rem 0;
            }

            p {
                color: white;
                font-size: 2rem;
                font-weight: 600;
                line-height: 3.5rem;

                .Warning {
                    color: #ff8237;
                }

                .Suggestion {
                    opacity: 0.5;
                }
            }
        }

        .ActionContainer {
            .ButtonContainer {
                display: flex;
                flex-flow: column nowrap;
                align-items: center;
                justify-content: space-around;

                .PrimaryButton {
                    border: 0;
                    background-color: var(--c-blue-mid);
                    color: white;
                    font-size: 2rem;
                    text-transform: uppercase;
                    font-weight: 700;
                    letter-spacing: 0.15rem;
                    padding: 2rem 3rem;
                    border-radius: 1rem;
                    font-family: var(--f-font-family);
                    margin-top: 2rem;
                    transition: opacity 0.25s;
                    cursor: pointer;

                    &:active {
                        background: #40c0fc;
                    }
                }

                .SecondaryButton {
                    font-family: var(--f-font-family);
                    color: var(--c-gray-mid-light);
                    background: none;
                    border: none;
                    font-weight: 600;
                    margin-top: 1rem;
                    font-size: 2rem;
                    transition: color 0.25s;
                    cursor: pointer;

                    &:active {
                        color: var(--c-blue-mid);
                        transition: color 0s;
                    }
                }
            }

            .ProgressContainer {
                display: flex;
                flex-flow: column nowrap;
                justify-content: space-around;
                // Fixed width align left to not reflow as much when changing %
                align-items: left;
                width: 17rem;

                progress[value] {
                    appearance: none; // Reset for webkit browsers
                    height: 2rem;
                    border-radius: 1rem;
                    width: 100%;
                }

                progress[value]::-webkit-progress-bar {
                    background-color: var(--c-blue-medium-dark);
                    border-radius: 1rem;
                }

                progress[value]::-webkit-progress-value {
                    background: linear-gradient(to right, #02c8ef, #0197db);
                    border-radius: 1rem;
                }

                span {
                    color: white;
                    font-size: 1.5rem;
                    font-weight: 500;
                    margin-top: 1rem;
                }
            }
        }
    }

    hr {
        border: 0.25rem solid white;
        border-radius: 0.25rem;
        width: 25%;
    }
}
