.DeviceContainer {
    height: 80vh;
    width: 100vw;
    position: relative;
    overflow: visible;

    .EricssonLogoMinimal {
        position: absolute;
        width: 3rem;
        height: auto;
        top: 4rem;
        left: 5rem;
        opacity: 0.55;
    }

    .EricssonTagline {
        position: absolute;
        color: #d5d5d5;
        top: 0.5rem;
        left: 12rem;
        font-size: 2.2rem;
        line-height: 2.6rem;
        opacity: 0.55;
        width: 35rem;
    }

    .DeviceFrame {
        position: absolute;
        width: 100vw;
        height: 100vh;

        // Background
        background-image: url('../../assets/img/center-stack-display.png');
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-position: 50% 10rem;
    }

    .DeviceContent {
        position: absolute;
        z-index: 10;
        background: hsla(0, 0, 0, 1);
        overflow: hidden;

        .AppContentContainer {
            width: 100%;
            height: 100%;
        }
    }
}
