.MusicApp {
    background: linear-gradient(#2e333d 0%, #171a22 100%);
    width: 100%;
    height: 100%;

    .TopBar {
        width: 100%;
        height: 10rem;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 4rem;

        img {
            width: 6rem;
            height: 6rem;
            border-radius: 3rem;
            border: 0.33rem solid #d8d8d8;
            margin-right: 2rem;
        }

        span {
            color: white;
            font-size: 2rem;
            font-weight: 700;
            line-height: 1rem;

            &.Time {
                width: 6rem;
            }
        }
    }

    .NowPlayingContainer {
        .ControlsContainer {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-evenly;

            .Previous,
            .Next {
                width: 13rem;
                height: 13rem;
                border: none;
                border-radius: 6.5rem;
                background: linear-gradient(148deg, #23262d 10%, #282c36 40%);
                box-shadow: -0.5rem -1rem 2rem -0.5rem rgba(255, 255, 255, 0.05), 0.5rem 1rem 1.25rem -0.5rem rgba(0, 0, 0, 0.2);
                padding: 0;

                img {
                    position: relative;
                    width: 5rem;
                    top: 0.25rem;
                }
            }

            .Previous {
                img {
                    right: 0.25rem;
                }
            }

            .Next {
                img {
                    left: 0.5rem;
                }
            }
        }

        .PlayPause {
            width: 38rem;
            height: 38rem;
            border: none;
            border-radius: 19rem;
            background: linear-gradient(135deg, #23262d 0%, #282c36 50%);
            box-shadow: -0.75rem -1rem 1.25rem -0.5rem rgba(255, 255, 255, 0.05), 1rem 1rem 1.25rem 0 rgba(0, 0, 0, 0.2);
            padding: 0;
            position: relative;

            .PlayingAlbumArt {
                width: 34rem;
                margin: 2rem;
            }

            .PlayPauseIcon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 6rem;
            }
        }

        .MetadataContainer {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            margin-top: 2rem;

            h2 {
                color: white;
                font-size: 2.2rem;
                font-weight: 700;
                line-height: 1rem;
                letter-spacing: 0.1rem;
            }

            h3 {
                color: white;
                font-size: 1.6rem;
                font-weight: 700;
                line-height: 1rem;
                opacity: 0.5;
                letter-spacing: 0.1rem;
            }

            .WaveformContainer {
                display: flex;
                flex-flow: row nowrap;
                width: 100%;
                justify-content: center;
                align-items: center;
                margin-top: 3rem;

                img {
                    width: 66%;
                    max-width: 80rem;
                    margin: 0 2rem;
                }

                span {
                    color: #20bcba;
                    font-size: 1.5rem;
                    font-weight: 500;
                    line-height: 1.5rem;
                }
            }
        }
    }

    .PlaylistAndSimilarArtistsContainer {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        margin: 6rem auto;
        padding: 0 4rem;
        max-width: 110rem;

        .PlaylistContainer {
            width: calc(60% - 6rem);

            .Divider {
                border: none;
                height: 0.75rem;
                background: #1c2028;
                box-shadow: inset 0 0.25rem 0.4rem 0 rgba(0, 0, 0, 0.18), inset 0 -0.25rem 0.5rem 0 rgba(255, 255, 255, 0.05);
                border-radius: 9.5px;
                border-radius: 0.5rem;
                margin: 2rem -1rem 0;
            }
        }

        .SimilarArtistsContainer {
            width: 40%;
        }

        ul {
            padding: 0;
            margin: 2rem 0 0 0;
        }

        .PlaylistContainer h2,
        .SimilarArtistsContainer h2,
        .PlaylistContainer .SongNumber,
        .PlaylistContainer .SongTitle,
        .SimilarArtistsContainer .ArtistName {
            color: white;
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 1.5rem;
            letter-spacing: 0.05rem;
            margin: 0;
            padding: 0;
        }

        .PlaylistContainer .ArtistName,
        .SimilarArtistsContainer .AlbumName {
            color: white;
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 1.5rem;
            letter-spacing: 0.05rem;
            opacity: 0.5;
            margin: 0;
            padding: 0;
        }
    }

    .PlaylistItem {
        list-style-type: none;
        display: flex;
        flex-flow: row nowrap;
        height: 8rem;
        align-items: center;
        justify-content: space-between;

        .SongNumber {
            width: 1.5rem;
            flex-grow: 0;
            margin: 0 1.5rem 0 2rem !important; // override general definition from above
        }

        .IconWrapper {
            width: 2rem;
            margin-right: 2.5rem;
            align-self: start;
            margin-top: 2.75rem;

            .MusicAnimationWrapper {
                position: relative;
                width: 2.5rem;
                height: 100%;

                .MusicNoteIcon {
                    width: 2.5rem;
                    left: -0.25rem;
                }
            }

            .DownloadAnimationWrapper {
                position: relative;
                width: 2.5rem;
                overflow: hidden;
                left: -0.25rem;
                opacity: 1;

                .DownloadIcon {
                    width: 2.5rem;
                    height: 2.5rem;
                }

                * {
                    fill: #d1dddd;
                }
            }
        }

        .SongTitleAndArtistNameContainer {
            display: flex;
            flex-flow: column nowrap;
            flex-grow: 1;
            margin-right: 2rem;

            .SongTitle {
                margin-bottom: 1rem;
            }
        }

        .SongLength {
            flex-grow: 0;
            color: #20bcba;
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 1.5rem;
            width: 4rem;
            margin-right: 2rem;
        }

        .MoreActions {
            background: none;
            flex-grow: 0;
            border: none;
            padding: 0;
            margin: 0;
            margin-right: 1.5rem;

            img {
                width: 0.75rem;
            }
        }

        &.Active {
            background: #15181f;
            border-radius: 1rem;
        }
    }

    .SimilarArtistsContainer {
        li {
            list-style-type: none;
            background: #1c2029;
            box-shadow: -0.5rem -1rem 2rem -0.5rem rgba(255, 255, 255, 0.05), 0.5rem 1rem 1.25rem -0.5rem rgba(0, 0, 0, 0.2);
            border-radius: 13px;
            margin-bottom: 2rem;
            display: flex;
            flex-flow: row nowrap;

            img {
                width: 8rem;
                height: 8rem;
                margin: 1rem 3rem;
            }

            div {
                display: flex;
                flex-flow: column nowrap;
                justify-content: center;

                .ArtistName {
                    margin-bottom: 1rem !important; // override general definition from above
                }
            }
        }
    }

    .ScenarioStartTrigger {
        position: absolute;
        right: 0;
        height: 100%;
        width: 20rem;
        opacity: 0;
    }
}
