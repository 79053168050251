// Reset all elements to border box for much easier styling
// See https://www.paulirish.com/2012/box-sizing-border-box-ftw/
* {
    box-sizing: border-box;
}

// Other reset stuff

button,
a,
div,
p {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                          supported by Chrome, Opera and Firefox */
}

html,
body {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

body {
    position: relative;
    margin: 0;
    line-height: 1;
    background-color: #fff;
    color: #000;
    font-family: var(--f-font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:focus {
    outline: none;
}

:root {
    // Super responsive design -- all elements scale to viewport height
    font-size: 0.8vh;

    // CSS Variables

    // Aspect ratio 1024 x 1366
    // Use VH to make responsive sizing
    --window-scale-modifier: 0.000575;
    --screen-height-windowed: 62.836vh;
    --screen-width-windowed: 47.104vh;
    --screen-height-fullscreen: 100vh;
    --screen-width-fullscreen: 74.963396779vh;

    // Colors
    --c-gray-mid-light: hsla(218, 4%, 60%, 1);
    --c-blue-mid: hsla(199, 99%, 43%, 1);
    --c-blue-dark: hsla(220, 15%, 20%, 1);
    --c-blue-medium-dark: hsla(218, 15%, 14%, 1);
    --c-blue-extra-dark: hsla(223, 14%, 10%, 1);

    // Fonts
    --f-font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.App {
    background: radial-gradient(circle at top, #0d0e11 0%, #020203 33%, #000 50%);
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-flow: column nowrap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('assets/fonts/Montserrat-Black.ttf');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-BlackItalic.ttf');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-ExtraBold.ttf');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-ExtraBoldItalic.ttf');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-BoldItalic.ttf');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-SemiBoldItalic.ttf');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-MediumItalic.ttf');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-Italic.ttf');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-Light.ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-LightItalic.ttf');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-ExtraLight.ttf');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-ExtraLightItalic.ttf');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-Thin.ttf');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-ThinItalic.ttf');
    font-weight: 100;
    font-style: italic;
}
