.PresentationContainer {
    width: 100vw;
    height: 20vh;
    position: relative;
    overflow: hidden;

    .PresentationContentPlaceholder {
        // Necessary to set position absolute for crossfade
        position: absolute;
        display: flex;
        flex-flow: row nowrap;
        // Set width to same width as screen
        height: 100%;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0 2rem;

        // Override margin of last child so it's always centered
        // Regardless of how many children we have
        :last-child {
            margin-right: 0 !important;
        }

        .Meta {
            display: flex;
            flex-flow: row;
            justify-content: flex-start;
            align-items: center;
            overflow: hidden;
            margin-right: 4rem;

            .Number {
                font-size: 6rem;
                font-weight: bold;
                color: white;
                margin-right: 2rem;
            }

            h2 {
                font-size: 2.5rem;
                line-height: 3rem;
                letter-spacing: 0.05rem;
                font-weight: 700;
                color: hsla(0, 0, 100%, 0.5);
            }
        }

        .Description {
            color: hsla(0, 0, 100%, 0.75);
            font-size: 2.5rem;
            line-height: 3rem;
            width: 45rem;
            margin-right: 4rem;
        }

        .ScenarioEventButtonsContainer {
            width: 20rem;

            .ScenarioControlButton {
                border: 0;
                color: white;
                font-size: 1.75rem;
                text-transform: uppercase;
                font-weight: 700;
                letter-spacing: 0.15rem;
                padding: 2.5rem 3rem;
                border-radius: 1rem;
                background-color: var(--c-blue-mid);
                box-shadow: 0 1rem 2rem -1rem rgba(0, 0, 0, 0.5);
                font-family: var(--f-font-family);
                width: 20rem;
                margin-bottom: 2rem;
                cursor: pointer;

                :last-child {
                    margin-bottom: 0 !important;
                }

                &:active {
                    background: #40c0fc;
                }
            }
        }
    }

    .AutoplayProgress {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 0.5rem;
        background: hsla(0, 0, 100, 0.25);
    }
}
