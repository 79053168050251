.AppSwitcher {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    .AppSwitcherButton {
        width: 8rem;
        height: 8rem;
        background: hsla(225, 13%, 12%, 0.9);
        border: 0.33rem solid #15bbb7;
        color: #15bbb7;
        font-weight: bold;
        position: absolute;
        border-radius: 100%;
        transform: translateX(-50%);
        left: 50%;
        transition: background-color 0.25s;
        pointer-events: visible;
        cursor: pointer;

        &.Menu {
            bottom: 2rem;
        }

        // Button Icon
        img {
            width: 40%;
            height: 40%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        &:active {
            cursor: pointer;
            background-color: #15bbb7;
            transition: background-color 0s;
        }
    }

    .Info {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
        padding: 10rem;
        justify-content: flex-start;
        align-items: center;

        .EricssonLogo {
            opacity: 0.9;
            width: 4rem;
            margin-top: 16rem;
            text-align: center;

            * {
                fill: #d5d5d5;
            }
        }

        h3 {
            color: #d5d5d5;
            font-size: 3rem;
            font-weight: 600;
            letter-spacing: 0.05rem;
            text-align: center;
            line-height: 4.2rem;
            margin-top: 2rem;
            opacity: 0.9;
            width: 100%;
        }

        h4 {
            color: #d5d5d5;
            font-size: 2.6rem;
            font-weight: 500;
            line-height: 3.2rem;
            opacity: 0.9;
            width: 100%;
        }
    }

    .FullscreenToggleButton,
    .AutoPlayToggleButton,
    .MuteToggleButton {
        position: absolute;
        top: 3rem;
        // width: 30rem;
        background: none;
        // height: 4rem;
        border: none;
        pointer-events: visible;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        color: #d5d5d5;
        font-size: 1.5rem;
        font-weight: 500;
        cursor: pointer;
        font-family: var(--f-font-family);

        svg {
            height: 3.5rem;
            width: 3.5rem;
            margin-bottom: 1rem;

            path {
                fill: #d5d5d5;
            }

            // Fullscreen button
            #Outline_Icons {
                stroke: #d5d5d5;
            }
        }

        div .Key {
            // border: 0.2rem solid #d5d5d5;
            border-radius: 0.5rem;
            display: inline-flex;
            line-height: 0rem;
            width: 2rem;
            font-size: 1rem;
            height: 2rem;
            font-weight: 600;
            justify-content: space-around;
            align-items: center;
            margin-left: 1rem;
        }

        &:active {
            color: white;

            svg {
                height: 3.5rem;
                width: 3.5rem;

                path {
                    fill: white;
                }

                // Fullscreen button
                #Outline_Icons {
                    stroke: white;
                }
            }

            div .Key {
                border: 0.3rem solid white;
            }
        }
    }

    .FullscreenToggleButton {
        right: 5rem;
    }

    .MuteToggleButton {
        right: 50%;
        width: 5rem;
        height: 5rem;
        transform: translateX(50%);
        top: 3rem;
        justify-content: center;
        align-self: center;
        padding: 0;

        svg {
            width: 100% !important;
            height: 100% !important;
        }
    }

    .AutoPlayToggleButton {
        left: 5rem;
    }

    .TriggerScenarioExplanation {
        color: #d5d5d5;
        font-size: 2.2rem;
        font-weight: 500;
        letter-spacing: 0.05rem;
        width: 25rem;
        position: absolute;
        right: 1rem;
        bottom: 1rem;
        border: 0.3rem solid #d5d5d5;
        border-radius: 2rem;
        padding: 2rem;
        background: hsla(0, 0, 0, 0.15);
        font-family: var(--f-font-family);
        pointer-events: visible;
        opacity: 0.75;

        &:active {
            color: white;
            border: 0.3rem solid white;
        }
    }
}
