.OtaApp {
    background-image: linear-gradient(180deg, #2e333d 0%, #171a22 87%);
    width: 100%;
    height: 100%;

    .TopBar {
        width: 100%;
        height: 10rem;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 4rem;

        img {
            width: 6rem;
            height: 6rem;
            border-radius: 3rem;
            border: 0.33rem solid #d8d8d8;
            margin-right: 2rem;
        }

        span {
            color: white;
            font-size: 2rem;
            font-weight: 700;
            line-height: 1rem;
            width: 6rem;
        }
    }

    .Content {
        padding: 4rem 4rem 16rem 4rem;
        height: calc(100% - 10rem);
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        position: absolute;
        width: 100%;

        .Top {
            h2 {
                color: white;
                font-size: 4.5rem;
                font-weight: 700;
                line-height: 1rem;
                letter-spacing: 0.1rem;
                margin: 6rem 0 6rem 0;
            }

            .Divider {
                border: none;
                height: 0.75rem;
                background: #292e37;
                box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.18), inset 0 -2px 5px 0 rgba(255, 255, 255, 0.04);
                border-radius: 9.5px;
                margin: 3rem 0 0 0;
            }

            h3 {
                color: white;
                font-size: 2.2rem;
                font-weight: 700;
                line-height: 1rem;
                letter-spacing: 0.15rem;
            }

            .TimePickerContainer {
                padding-right: 16rem;
            }

            .Description,
            .TimeEstimate {
                color: white;
                font-size: 2.8rem;
                font-weight: 500;
                line-height: 3rem;
                letter-spacing: 0.1rem;
                display: block;
                margin-bottom: 2rem;
            }

            .TimeEstimate {
                color: #00bdbb;
                font-weight: 700;
            }

            .TimePicker {
                display: grid;
                grid-template-columns: 4fr 2fr 1fr 2fr;
                grid-template-rows: 1fr 2fr 0.25rem 2fr 0.25rem 2fr;
                width: 100%;
                height: 30rem;
                color: white;
                text-align: center;
                align-items: center;

                .Row1 {
                    grid-row: 1;
                }

                .Row2 {
                    grid-row: 2;
                }

                .Row3 {
                    grid-row: 3;
                }

                .Row4 {
                    grid-row: 4;
                }

                .Row5 {
                    grid-row: 5;
                }

                .Row6 {
                    grid-row: 6;
                }

                .Col1 {
                    grid-column: 1;
                }

                .Col2 {
                    grid-column: 2;
                }

                .Col3 {
                    grid-column: 3;
                }

                .Col4 {
                    grid-column: 4;
                }

                .HMIndicator {
                    font-size: 2rem;
                    font-weight: 600;
                    text-align: center;
                }

                .Day {
                    font-size: 3.25rem;
                    font-weight: 500;
                    text-align: left;
                    margin-left: 3rem;
                }

                .Time {
                    font-size: 3.25rem;
                    font-weight: 700;
                }

                .Inactive {
                    opacity: 0.25;
                }

                .Separator {
                    background: #00bdbb;
                    grid-column: 1 / span 4;
                    height: 100%;
                }
            }
        }

        .Bottom {
            span {
                color: white;
                font-size: 2.2rem;
                line-height: 3.25rem;
                font-weight: 500;
                display: block;
                margin-bottom: 4rem;
                letter-spacing: 0.05rem;
            }

            button {
                border: none;
                background: none;
                height: 10rem;
                padding: 0 5rem;
                color: white;
                font-family: var(--f-font-family);
                font-size: 2rem;
                text-transform: uppercase;
                font-weight: 600;
                letter-spacing: 0.1rem;
                border-radius: 1rem;
                margin-right: 2rem;
            }

            .Primary {
                background: #0197db;

                &:active {
                    background: #40c0fc;
                }
            }

            .Secondary {
                background: #2d323c;

                &:active {
                    background: #49505f;
                }
            }
        }
    }
}
